import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import {
  PlayerTransactionsReport,
  PlayerTransactionsReportVariables,
} from './__generated__/PlayerTransactionsReport';
import { queryParams } from './queryParams';

const PLAYER_TRANSACTIONS_REPORT_QUERY = gql`
  query PlayerTransactionsReport(
    $id: String
    $rawId: String
    $playerId: ID
    $brand: BrandEnum
    $gameRoundId: String
    $minAmount: PositiveBigDecimal
    $maxAmount: PositiveBigDecimal
    $from: OffsetDateTime
    $to: OffsetDateTime
    $currency: String
    $paymentStatus: [PaymentStatus!]
    $paymentMethod: String
    $transactionTypes: [TransactionType!]
    $adjustmentType: AdjustmentType
    $adjustmentCategory: AdjustmentCategory
    $orderBy: TransactionSearchOrder
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      exportedTransactionsV2(
        id: $id
        rawId: $rawId
        playerId: $playerId
        brand: $brand
        gameRoundId: $gameRoundId
        minAmount: $minAmount
        maxAmount: $maxAmount
        from: $from
        to: $to
        currency: $currency
        paymentStatus: $paymentStatus
        transactionTypes: $transactionTypes
        paymentMethod: $paymentMethod
        adjustmentType: $adjustmentType
        adjustmentCategory: $adjustmentCategory
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        url
      }
    }
  }
`;

export default function usePlayerTransactionsReport(playerId: string) {
  const [query] = useQueryParams(queryParams);
  const client = useClient();

  const generate = async () => {
    const variables: PlayerTransactionsReportVariables = {
      ...mapVariables(query),
      playerId,
    };

    return client
      .query<PlayerTransactionsReport, PlayerTransactionsReportVariables>(
        PLAYER_TRANSACTIONS_REPORT_QUERY,
        variables,
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedTransactionsV2.url;

        if (url) {
          saveAs(url, 'player-transactions-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
